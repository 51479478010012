.ImageSizeAbsolute {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;

  .Polaris-TextField__Spinner {
    display: none;
  }

  button {
    width: 44px;
    height: 35px;
    cursor: pointer;
    margin-top: 24px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}