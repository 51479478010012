.ChangeProduct{
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  .product-content{
    display: flex;
    align-items: flex-start;
    .img{
      border: 1px solid #C9CCCF;
      box-sizing: border-box;
      border-radius: 4px;
      width: 60px;
      min-width: 60px;
      height: 60px;
      margin-right: 12px;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .title{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      font-weight: 600;
    }
    .text{
      font-size: 14px;
      line-height: 20px;
    }
    
  }
  .Polaris-Button{
    min-width: 150px;
  }
}