.ContentContainer {

  display: flex;
  justify-content: center;
  align-items: center;

  .DesktopFrame {
    border-width: 0;
    width: 100%;
    height: 1000px;
  }

  .TabletFrame {
    border-width: 0;
    width: 820px;
    height: 1180px;
  }

  .MobileFrame {
    border-width: 0;
    width: 430px;
    height: 932px;
  }
}
