.PrePaymentWarning {
  width: 100%;
  padding: 32px 20px;
  display: flex;
  color: #202223;
  .text-block {
    width: 374px;
    min-width: 374px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-size: 18px;
    line-height: 120%;
    margin-right: 20px;
    .highlighted-text {
      font-size: 36px;
      line-height: 120%;
      font-weight: 500;
    }
  }
  .image-block {
    flex: 1 1 auto;
  }
}

@media (max-width: 414px) {
}

@media (max-width: 768px) {
  .PrePaymentWarning {
    flex-direction: column;
    .text-block {
      width: 100%;
      min-width: unset;
      text-align: center;
      margin-right: 0;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 20px;
      .highlighted-text {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .image-block {
      flex: unset;
      width: 100%;
    }
  }
}