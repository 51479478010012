.FormStep2{
  .custom-pagination{
    position: relative;
    z-index: 100;
  }
  
  .title{
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #202223;
  }
  .Variant{
    margin-top: 32px !important;
    .Polaris-Card{
      position: relative;
    }
    .VariantItem:last-child{
      border-bottom: none;
    }
    .disabled{
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f6f6f7;
      z-index: 99;
      opacity: 0.3;
      top: 0;
    }
    .title{
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
      color: #202223;
      display: flex;
      align-items: center;
      .Polaris-Icon{
        margin-left: 8px;
        svg{
          fill: #BABEC3;
        }
      }
    }
    .text{
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
      color: #6D7175;
      text-transform: uppercase;
    }
  }
  .block-info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #FFFFFF;
    /* Depth: 02 */

    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .video{
      width: 290px;
      img{
        max-width: 100%;
      }
    }
    .text-content{
      width: 100%;
      padding: 20px;
      .title{
        font-size: 16px;
        line-height: 24px;
        color: #202223;
        margin-bottom: 20px;
      }
      .text{
        font-size: 14px;
        margin-bottom: 20px;
        line-height: 20px;
      }
    }
  }
}