.Dashboard{
  .Polaris-DataTable__TableRow:hover .actions {
    opacity: 1;
  }

  .spinner-container{
    height: calc(100vh - 54px);
  }
  .block{
    display: flex;
    align-items: center;
    // justify-content: center;
    min-height: 40px;
  }
  .actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    gap: 20px;
    .Polaris-Link {
      margin-left: 10px;
    }
  }
  .productName{
    display: flex;
    align-items: center;
    img{
      border: 1px solid #C9CCCF;
      box-sizing: border-box;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      img{
        max-width: 100%;
        height: 100%;
      }
    }
  }
  .status{
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 13px;
    line-height: 16px;
    color: #202223;
    display: flex;
    img{
      margin-right: 4px;
    }
    &.completed{
      background: #AEE9D1;
    }
  }
  .AppExtensionWarning {
    padding-bottom: 6px;
    .LearnMore {
      display: flex;
      span.text {
        padding-right: 4px;
      }
      .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
      }

      /* Tooltip text */
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        color: black;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px;

        cursor: default;
      }

      /* Show the tooltip text when you mouse over the tooltip container */
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }
    }
  }

  .FreeLimitaionWarning {
    padding-bottom: 6px;
    .LearnMore {
      display: flex;
      span.text {
        padding-right: 4px;
      }
    }
  }
  .guide-link {
    text-decoration: underline; /* Underline the text to indicate a link */
    cursor: pointer; /* Show a pointer cursor on hover to indicate interactivity */
    /* Add any additional styles as needed */
  }

  .guide-link:hover {
    color: #009966; /* Change the text color on hover for a subtle effect */
  }
}
