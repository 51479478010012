.FormStep1{
  width: 100%;
  display: flex;
  align-items: flex-start;
  .text-content{
    color: #202223;
    min-width: 373px;
    width: 373px;
    .title{
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    p{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
  .form-content{
    width: 100%;
    margin-left: 20px;
  }
}