.Color {
  display: flex;

  .ColorPattern {
    right: 22%;
    margin-top: 10px;
    height: 16px;
    width: 16px;
    border: 1px  grey solid;
    border-radius: 5px;
    position: absolute;
    z-index: 100;
  }

  .ColorText {
    width: 89%;
    padding-right: 10px;
  }

  .ColorModal {
    width: 210px;
    position: absolute;
    right: 18%;
    z-index: 101;
    border-radius: 3px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    border-radius: 3px;

    .Polaris-Button {
      width: 47%;
    }
    .Polaris-Button--primary {
      background-color: #3f4eae;
    }

    .PickerContainer {
      padding-left: 7px;
      padding-top: 7px;
      padding-bottom: 7px;
      text-align: center;
      width: 100%;
    }

    .ButtonGroup {
      border-top: 1px solid #C4CDD5;
      background-color: #F4F6F8;
      box-sizing: border-box;
      display: flex;
      align-items: s;
      padding: 7px;
      justify-content: space-between;
    }
  }
}