.PricingDetailing {
  border-radius: 9.028px;
  background: rgba(76, 195, 193, 0.37);
  text-align: center;
  margin-top: 12px;
  p {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .oldPrice {
    color: #797979;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 9.63px; /* 80.253% */
    text-decoration: line-through;
  }
  .newPrice {
    color: #258D8C;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.63px; /* 53.502% */
  }
  .text {
    color: #3E4155;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
}