.VariantItem{
  margin-bottom: 20px;
  border-bottom: 2px solid #D2D5D8;
  .title{
    margin-bottom: 20px !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-transform: uppercase;
  }
  .photos{
    margin-top: 20px;
    margin-bottom: 20px;
    // overflow-x: auto;
    .photo-items{
      display: flex;
      flex-wrap: wrap;
      .photo-item + .photo-item{
        margin-left: 8px;
      }
      .photo-item{
        width: 80px;
        min-width: 80px;
        height: 80px;
        position: relative;
        border: 1px solid #C9CCCF;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        .close{
          position: absolute;
          cursor: pointer;
          top: 2px;
          right: 2px;
          svg{
            fill: #8C9196;
          }
        }
      }
    }
  }
}

.VariantItem + .VariantItem{
  margin-bottom: 20px;
}

.ChangeImageVariant{
  margin-left: 10px;
}