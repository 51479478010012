.PricingDiscountVector {
  width: 129px;
  position: relative;

  span {
    position: absolute;
    left: 7%;
    right: 3.93%;
    top: 32%;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;

    color: #202223;

    transform: rotate(15deg);
  }
}