.TextEditInput{
  &.disabled{
    .tox-edit-area{
      position: relative;
      &::after{
        position: absolute;
        content: ' ';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.07);
        z-index: 9999;
  
      }
    }
  }
}