.PauseSelectVariant{
  padding: 16px 0px;
  .border-top{
    box-shadow: inset 0px 1px 0px #E4E5E7;
  }
  .title{
    padding: 0 20px;
    font-size: 16px;
    line-height: 24px;
    color: #202223;
    font-weight: 500;
  }
  .variant{
    padding: 20px;
    .Polaris-ChoiceList__Title{
      font-weight: 500;
    }
    .Polaris-Choice__Label{
      color: #6D7175;
    }
  }
  .footer{
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    .Polaris-Button + .Polaris-Button{
      margin-left: 7px;
    }
  }
  .info{
    padding: 20px;
    padding-top: 0;
    display: flex;
    align-items: flex-start;
    .Polaris-Icon{
      margin-right: 6px;
      margin-top: 5px;
      padding: 0;
    }
  }
}