.StatusTestAb{
  margin: 0 5px;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 16px;
  color: #202223;
  display: flex;
  img{
    margin-right: 4px;
  }
  &.completed, &.winner_assigned{
    background: #AEE9D1;
  }
  &.running{
    background: #FFD79D;
  }
  &.pending, &.paused{
    background: #FFEA8A;
  }
}