.Plan {
  overflow: hidden;
  display: flex;
  height: max-content;
  flex: 1 1 calc((100% / 2) - 1rem);
  flex-direction: column;
//   box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: #fff;


  .block-header {
      box-shadow: inset 0px -1px 0px #e1e3e5;
      padding: 2rem;

      .header-title {
          width: max-content;
          font-size: 28px;
          font-weight: 600;
          font-style: normal;
          line-height: 32px;
          margin: auto;
      }
  }
  .block-body {
      padding: 16px 20px 40px 20px;
      display: flex;
      flex-direction: column;

      .body-price,
      .body-duration
      {
          width: max-content;
          margin: auto;
      }

      .body-oldPrice {
          margin: auto;
          height: 24px;
          font-size: 16px;
          line-height: 24px;
          color: #8c9196;
          text-decoration: line-through;
          text-align: center;
      }

      .body-price {
          margin: 8px auto 16px;
          .price-value {
              font-size: 42px;
              font-weight: 600;
              font-style: normal;
              line-height: 44px;
              color: #202223;
          }

          .price-sufix {
              font-size: 16px;
              line-height: 24px;
              color: #6d7175;
          }
      }

      .body-duration {
          padding: 0.25rem 0.8rem;
          font-size: 13px;
          line-height: 16px;
          color: #202223;
          background-color: #a4e8f2;
          border-radius: 10px;
      }

      .body-specs {
          padding-top: 16px;
          margin-left: 18px;
          height: 300px !important;
          ul {
              margin: 0;
              white-space: normal;
              padding: 0;
              color: #6d7175;
              font-size: 14px;
              line-height: 20px;
              white-space: normal;
              word-wrap: break-word;
              li {
                  margin-top: 16px;
                  white-space: normal;
                  word-wrap: break-word;
              }
          }
      }
  }

  .block-footer {
      padding: 1.6rem;
      box-shadow: inset 0px 1px 0px #e4e5e7;

      .footer-btns {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
      }
  }

  .disabled-line-btn .Polaris-Button--disabled {
      text-decoration: line-through;
  }
}

@media (max-width: 705px) {
  .plan-block {
    flex: 1 1 100%;
    width: 100% !important;
    min-width: unset !important;
  }
}