


.TestForm{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;


  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: -10px;
    .col-2{
      width: 50%;
      padding: 10px;
    }
  }

 

  .m-b-20{
    margin-bottom: 20px;
  }
  .form-content{
    width: 100%;
    padding: 32px 20px 0 20px;
    box-sizing: border-box;
    // height: 100%;
    flex: auto;
  }
  .form-footer{
    // min-height: 78px;
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: inset 0px 1px 0px #E4E5E7;
    .Polaris-Button__Text{
      display: flex;
      align-items: center;
    }
  }
}

.spinner-container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  .progress-bar{
    margin-top: 20px;
    max-width: 300px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .TestForm{
    .form-content{
      .FormStep1{
        flex-direction: column;
        .text-content{
          width: 100%;
          min-width: 100%;
        }
        .form-content{
          margin-left: 0;
        }
        .ChangeProduct{
          flex-direction: column;
        }
      }
      .FormStep2{
        .Variant{
          flex-direction: column;
          margin: 0;
          .col-2{
            width: 100%;
          }
        }
      }
      .FormStep3{
        flex-direction: column;
        .row{
          width: unset;
        }
        .text-content{
          width: 100%;
          min-width: unset;
        }
        .form-content{
          margin-left: 0;
        }
        .block2{
          flex-direction: column-reverse;
          // margin: 0;
          .col-tmp{
            width: 100%;
          }
        }
      }
    }
    .VariantItem{
      .row{
        flex-direction: column;
        .col-2{
          width: 100%;
        }
      }
    }
    .block-info{
      flex-direction: column;
      .video{
        width: 100%;
        img{
          max-width: 100%;
          max-height: 100%;
          width: 100%;
        }
      }
    }
  }
}