.ProgressBarTest{
  width: 300px;
  background: rgb(228, 229, 231);
  border-radius: 4px;
  height: 16px;
  .progress{
    background: rgb(68, 157, 167);
    height: 100%;
    border-radius: 4px;
  }
}