.MultiStepForm {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .form-content{
    width: 100%;
    padding: 32px 20px 0 20px;
    box-sizing: border-box;
    flex: auto;
  }
  .form-footer{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:  space-around;
    box-shadow: inset 0px 1px 0px #E4E5E7;
    .Polaris-Button__Text{
      display: flex;
      align-items: center;
    }
  }
  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    .col-2{
      width: 50%;
      padding: 5px;
    }
  }
}