.Polaris-Popover__Pane{
  .date-time-content{
    // width: 269px;
    padding: 15px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .point{
      margin: 0 5px;
      font-size: 14px;
      line-height: 20px;
    }
    .input-time{
      width: 55px;
      .Polaris-Icon{
        cursor: pointer;
        fill: #1D6EB9;
      }
    }
    .blocks{
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .block{
        width: 55px;
        background: #BABFC3;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 8px 16px;
        color: #202223;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        &.active{
          background-color: #1D6EB9;
          color: white;
        }
      }

      .block + .block{
        margin-top: 10px;
      }
    }
  }
}

.DateTime {
  position: relative;
  margin-top: 4px;
  width: 100%;
  .date-time-content{
    width: 334px;
  }
  .drop-wrapper {
    
    position: absolute;
    z-index: 99;
    /* top: 0; */
    width: 100%;
    background: white;
    border: 1px solid #CECECE;
    /* margin-top: -1px; */
    /* border-radius: 3px; */
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    right: 0;
  } 
  .DateTime-Input{
    display: flex;
    width: 100%;
    font-weight: 400;
    line-height: 2.4rem;
    text-transform: initial;
    letter-spacing: initial;
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 3.6rem;
    padding: 0.5rem 0.8rem 0.5rem 1.2rem;
    border: 1px solid #C4CDD5;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border-radius: 3px;
    cursor: pointer;

    &.active{
      border: 1px solid #C4CDD5;
      border-color: #2a6dc9;
      -webkit-box-shadow: 0px 0px 1px 1px rgba(42,109,201,1);
      -moz-box-shadow: 0px 0px 1px 1px rgba(42,109,201,1);
      box-shadow: 0px 0px 1px 1px rgba(42,109,201,1);
    }
  }

  .drop-date{
    position: absolute;
    z-index: 99;
    width: 100%;
    background: white;
    border: 1px solid #CECECE;
    min-width: 334px;
    // padding: 15px;
    left: 0;
    margin-top: 8px;
    border-radius: 3px;
    .Polaris-DatePicker{
      margin: 2rem;
    }
    .inputs{
      display: flex;
      justify-content: space-between;
      padding: 2rem;
      .Polaris-TextField{
        width: 241px;
      }
      padding-bottom: 0;
    }
    .footer{
      // margin-top: 1.6rem;
      border-top: 0.1rem solid #dfe3e8;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
    }
  }
}