.html-modal {
  position: relative;
  width: 600px !important;
  left: calc(99% - 400px);
  .text-container {
    text-align: center;
  }
  .Polaris-ButtonGroup {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 7px;
    display: flex;
    justify-content: flex-end;
  }
  .cm-editor {
    height: 620px;
    border: 0.5px solid black;
  }
}
