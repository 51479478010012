.Photos{
  margin-bottom: 20px;
  // overflow-x: auto;
  .photo-items{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    .photo-item{
      margin: 4px;
      width: 80px;
      min-width: 80px;
      height: 80px;
      position: relative;
      border: 1px solid #C9CCCF;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.6);
        z-index: 9999;
      }
      .close{
        position: absolute;
        background: white;
        border-radius: 50%;
        cursor: pointer;
        top: 2px;
        right: 2px;
        svg{
          fill: #8C9196;
        }
      }
    }
  }
}
