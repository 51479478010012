.NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  width: 100%;

  .Item {
    height: 100%;
  }

  .ChangeList {
    position: relative;
    width: 40%;
    display: flex;
    align-items: center;
    margin-left: 20px;

    .Dropdown {
      height: 70%;
      width: 40%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .Dropdown-Items {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;

      .Dropdown-No-Items {
        padding: 10px;
      }

      .Dropdown-Item {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Dropdown-Item-Buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }
    }
  }

  .Mode {
    display: flex;
    justify-content: center;
    width: 20%;

    .Controller {
      margin-top: 10px;
      display: flex;
    }

    .mode-button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 8px 16px;
      width: 144px;
      height: 36px;
      box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
      border: 1px solid #BABFC3;
      cursor: pointer;
    }

    .edit-mode-button {
      @extend .mode-button;
      border-radius: 4px 0px 0px 4px;
    }

    .interact-mode-button {
      @extend .mode-button;
      border-radius: 0px 4px 4px 0px;
    }

    .selected-mode {
      background: #F1F2F3;
    }
  }

  .SpecificMode {
    width: 40%;
    margin-right: 20px;

    div {
      width: 100%;
    }

    .Edit {
      display: flex;
      align-items: center;
      margin-top: 10px;
      gap: 30px;
      .DeviceType {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
      }

      .Save {
        width: 70px;
        text-align: right;
      }
    }

    .Interact {
      .GoToURL {
        display: flex;
        align-items: center;
        float: right;
        button {
          margin-left: 6px;
        }
        .Polaris-Labelled__Error {
          display: none;
        }
      }
    }
  }
}