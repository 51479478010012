.image {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.image .overlap-group-wrapper {
  height: 171px;
  overflow: hidden;
  width: 148px;
}

.image .overlap-group {
  height: 171px;
  position: relative;
}

.image .vector {
  height: 148px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 148px;
}

.image .mask-group {
  height: 171px;
  left: 0;
  position: absolute;
  top: 0;
  width: 148px;
}

.image .frame {
  background-image: url(https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/group-1@2x.png);
  background-size: 100% 100%;
  height: 69px;
  left: 54px;
  position: absolute;
  top: 41px;
  width: 80px;
}

.image .group {
  height: 8px;
  left: 28px;
  position: absolute;
  top: 17px;
  width: 34px;
}

.image .img {
  height: 3px;
  left: 7px;
  position: absolute;
  top: 40px;
  width: 52px;
}

.image .vector-2 {
  height: 3px;
  left: 7px;
  position: absolute;
  top: 45px;
  width: 52px;
}

.image .vector-3 {
  height: 3px;
  left: 7px;
  position: absolute;
  top: 50px;
  width: 52px;
}

.image .vector-4 {
  height: 3px;
  left: 7px;
  position: absolute;
  top: 35px;
  width: 52px;
}

.image .rectangle {
  background-color: #007f5f;
  border-radius: 1px;
  height: 14px;
  left: 7px;
  position: absolute;
  top: 17px;
  width: 16px;
}

.image .div {
  background-image: url(https://cdn.animaapp.com/projects/65293ec2d872a49b17c6cb6f/releases/65293f255724ca6e9a7b1564/img/group-2@2x.png);
  background-size: 100% 100%;
  height: 70px;
  left: 14px;
  position: absolute;
  top: 84px;
  width: 80px;
}

.image .group-2 {
  height: 1px;
  left: 11px;
  position: absolute;
  top: 83px;
  width: 1px;
}

.upsell-body-duration {
  padding: 0.25rem 0.2rem;
  font-size: 13px;
  line-height: 16px;
  color: #258D8C;
  background-color: #a4e8f2;
  border-radius: 6px;
  margin-left: 4px;
  font-weight: 600;
}