.WinnerStatus {
  .Polaris-Card  {
    width: 50%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    
    .item {
      padding: 20px;
    }

    .left {
      text-align: left;
    }
    
    .right {
      text-align: right;
    }

    .Title {
      font-size: 24px;
      padding: 12px;
    }

    .Winner {
      font-size: 20px;
      padding: 12px;
    }

    .Medal {
      padding-right: 40px;
    }
  }
}