.PricingMode {
  display: flex;
  flex-basis: 60%;

  .mode-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 8px 4px;
    width: 193px;
    height: 36px;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #BABFC3;
    cursor: pointer;
    background-color: #FFFFFF;
    font-size: 14px;
  }

  .monthly-mode-button {
    @extend .mode-button;
    border-radius: 4px 0px 0px 4px;
  }

  .yearly-mode-button {
    @extend .mode-button;
    border-radius: 0px 4px 4px 0px;
  }

  .selected-mode {
    background: #F1F2F3;
  }
}