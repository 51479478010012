.Text {
  .TextMode {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 5px;
    
    .left {
      align-self: flex-start;
    }
    .right {
      align-self: flex-end;
    }
  }
}