.Pricing {
  padding: .75rem;
  flex-direction: column;
  gap: 2rem;

  .PricingColumn {
    flex: 1;
    padding: 10px;
  }

  .page-body {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .plan-blocks {
      flex: 1 1 calc(65% - 1rem);
    }
  }

  @media (max-width: 705px) {
    .page-body.plan-blocks {
      width: 100% !important;
      flex: 1 1 100%;
    }
  }

  .text-14 {
    color: #202223;
    font-size: 14px;
    line-height: 20px;
  }

  .text-16 {
    color: #202223;
    font-size: 16px;
    line-height: 24px;
  }

  .text-28 {
    color: #202223;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }
}

.price-modal-test {
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.plan-blocks {
  // display: flex;
  gap: 2rem;
  margin-bottom: 1rem;

}

@media (max-width: 705px) {
  .plan-blocks {
      flex-wrap: wrap;
  }
}
