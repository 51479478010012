.Tab{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #F6F6F7;
  box-shadow: inset 0px -1px 0px #E1E3E5;
  .item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    font-size: 14px;
    line-height: 20px;
    &.active{
      box-shadow: inset 0px -3px 0px #008060;
    }
  }
}