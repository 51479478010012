.Details{
  margin: 16px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .block-left{
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      .Polaris-Button{
        margin-right: 8px;
      }
    }
    .actions{
      display: flex;
      > .Polaris-Button{
        margin-right: 8px;
      }
    }
  }
  .title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #202223;
  }
}