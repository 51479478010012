.Counter {
  overflow: hidden;
  display: flex;
  height: max-content;
  flex: 1 1 calc((100% / 2) - 1rem);
  flex-direction: column;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: #fff;

  .block-header {
    box-shadow: inset 0px -1px 0px #e1e3e5;
    padding: 2rem 0;

    .counter-title {
        width: max-content;
        font-size: 28px;
        font-weight: 600;
        font-style: normal;
        line-height: 32px;
        margin-left: 38px;
        // margin: auto;
    }
  }
  
  .block-body {
    padding: 16px 20px 40px;
    display: flex;
    flex-direction: column;

    .counter-body {
      padding-top: 16px;
      margin-left: 18px;
      // height: 300px !important;

      .impression-limit {
        font-family: 'SF Pro Text';
        font-weight: 500;
        font-size: 24px;
        line-height: 28.64px;
        margin-bottom: .75rem;
      }
      .impression-left {
        font-family: 'SF Pro Text';
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: .75rem;
      }
      .impression-warning {
        font-family: 'SF Pro Text';
        font-weight: 400;
        font-size: 14px;
        line-height: 16.7px;
        color: #EC2B1E;
        margin-bottom: .5rem;
      }
    }
  }
}