.content-form-section {
  margin-bottom: 15px;
  border-bottom: 1px solid #D2D5D8;
  padding-bottom: 15px;
}

.content-form-item {
  margin-bottom: 16px;
}

.content-form-button-group {
  float: right;
  padding-bottom: 20px;
}

.ContentTestHtmlEditor {
  width: 100%;
  height: 80.5px;
}

.ContentTestEditor {
  .Polaris-Modal-Dialog__Container {
    width: 384px;
    left: calc(99% - 384px);
  }
}

.html-modal-container {
  left: initial;
  width: initial;
  right: 11px;
}

.html-bottom-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.marginGroup .Polaris-TextField__Spinner {
  display: none;
}

.Polaris-Backdrop {
  animation: none 0s ease 0s 1 normal none running !important;
}

.content-form {
  padding: 20px;
}
// Quill Text Editor

div.ql-toolbar.ql-snow > span:nth-child(2) {
  margin-right: 4px;
  float: right;
}
