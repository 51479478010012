.Dashboard{
  .spinner-container{
    height: calc(100vh - 54px);
  }
  .block{
    display: flex;
    align-items: center;
    // justify-content: center;
    min-height: 40px;
  }
  .actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .Polaris-Button {
      margin-left: 10px;
    }
  }
  .productName{
    display: flex;
    align-items: center;
    img{
      border: 1px solid #C9CCCF;
      box-sizing: border-box;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      img{
        max-width: 100%;
        height: 100%;
      }
    }
  }
  .status{
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 13px;
    line-height: 16px;
    color: #202223;
    display: flex;
    img{
      margin-right: 4px;
    }
    &.completed{
      background: #AEE9D1;
    }
  }
  h1.parentBulkTestName {
    font-size: 30px;
    padding-bottom: 15px;
  }
}